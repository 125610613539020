<template>
    <el-dialog :model-value="changedits" lock-scroll title="修改数据" :fullscreen='fill_space' :before-close="cancels">
        <el-form :model="changeForms" :inline="true" label-position="top">
            <el-row>
                <el-col v-for="(item, index) in changeHeads" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="item.label" label-position="left" label-width='100%'>
                        <el-input v-model="changeForms[item.property]" v-if="item.type === 'number'" type="number"
                            :disabled='!item.edit' />
                        <el-input v-model="changeForms[item.property]" v-if="item.type === 'input'"
                            :disabled='!item.edit' />
                        <el-input v-model="changeForms[item.property]" :rows="2" type="textarea"
                            v-if="item.type === 'textarea'" :disabled='!item.edit' />
                        <el-select v-model="changeForms[item.property]" placeholder="选择" v-if="item.type === 'select'"
                            filterable :disabled='!item.edit'>
                            <el-option v-for="i in item.option" :key="i" :label="i" :value="i" />
                        </el-select>
                        <el-date-picker v-model="changeForms[item.property]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'date-picker'" :disabled='!item.edit' />
                        <el-date-picker v-model="changeForms[item.property]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'one-date-picker'" :disabled='!item.edit' />
                        <el-cascader v-model="changeForms[item.property]" :options="item.option" :props="props"
                            collapse-tags clearable v-if="item.type === 'cascader'" :disabled='!item.edit' />
                        <el-input v-model="changeForms[item.property]" :rows="2" v-if="item.type === 'disabled'"
                            disabled />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="cancels">取消</el-button>
                <!-- <el-button type="danger" @click="deletes">删除</el-button> -->
                <el-button type="primary" @click="updatas">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "CatChange",
        mounted() {
            let n = document.body.clientWidth
            if (n <= 800) {
                this.fill_space = true
            } else {
                this.fill_space = false
            }
        },
        props: {
            changeForms: Object,
            changeHeads: Array,
            changedits: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                props: {
                    multiple: true
                },
                fill_space: false
            }
        },
        emits: ['updatas', 'deletes', 'cancels'],
        methods: {
            updatas() {
                //将数据传递给父组件
                this.$emit('updatas', this.changeForms)
            },
            deletes() {
                this.$emit('deletes', this.changeForms)
            },
            cancels() {
                this.$emit('cancels')
            },
            createOption(v) {
                // 等级
                let readModify = []
                for (let key in v['readModify']) {
                    readModify.push({
                        value: key,
                        label: v['readModify'][key],
                    })
                }
                let option = []
                v['option'].forEach((v, k) => {
                    option.push({
                        value: k,
                        label: v,
                        children: readModify
                    })
                })
                return option
            }
        },
    }
</script>

<style scoped>
</style>