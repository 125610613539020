<template>
    <el-tabs @tab-click='getdata' class="class-tabs" tab-position="top" type="border-card"
        :style="'height: '+ (innerHeight - 78) +'px'" :padding="8">
        <el-tab-pane label="资产简报">
            <template #label>
                <span class="custom-tabs-label">
                    <el-icon>
                        <Odometer />
                    </el-icon>
                    <span>资产简报</span>
                </span>
            </template>
            <div id='sunburst' :style="'height: '+ (innerHeight - 158) +'px'"></div>
        </el-tab-pane>
        <el-tab-pane label="资产列表">
            <template #label>
                <span class="custom-tabs-label">
                    <el-icon>
                        <Notification />
                    </el-icon>
                    <span>资产列表</span>
                </span>
            </template>
            <el-container>
                <el-header height='15'>
                    <el-row justify="space-between">
                        <el-col :span="1">
                            <CatSearch :searchHead="tableHead" @search="cat_search_get" :searchForm='lookup.search'>
                            </CatSearch>
                        </el-col>
                        <el-col :span="6">
                            <el-input v-model="lookup.csearch" placeholder="手机编码/手机号/姓名" clearable prefix-icon="Search">
                                <template #append>
                                    <el-button @click="getdata" type="primary" plain>搜索</el-button>
                                </template>
                            </el-input>
                        </el-col>
                        <el-col :span="1">
                            <el-button size="small" type="primary" @click="ExporttAPI('#table-data')">导出表</el-button>
                        </el-col>
                    </el-row>
                </el-header>
                <el-main>
                    <el-table :data="fileList" stripe max-height="750" :height="(innerHeight - 225)" size="small"
                        v-loading="loading">
                        <el-table-column v-for="(item, index) in tableHeads" :key="index" :label="item.label"
                            :fixed="item.fixed" v-cloak='item.hide' :prop="item.property" show-overflow-tooltip
                            :sortable="item.sort">
                        </el-table-column>
                        <el-table-column fixed="right" label="操作">
                            <template #default>
                                <el-popover placement="bottom" trigger="click">
                                    <template #reference>
                                        <el-button size="small" type="success" plain>查看</el-button>
                                    </template>
                                    <template #default>
                                        <el-button-group>
                                            <el-button plain type="primary" @click="tabClick">编辑</el-button>
                                            <el-button plain type="success">分配</el-button>
                                        </el-button-group>
                                        <el-button-group>
                                            <el-button plain color="#626aef">转组</el-button>
                                            <el-button plain type="warning">迁粉</el-button>
                                        </el-button-group>
                                        <el-button-group>
                                            <el-button plain type="info">报封</el-button>
                                            <el-button plain type="danger">退库</el-button>
                                        </el-button-group>
                                    </template>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-main>
                <el-footer height='15'>
                    <el-pagination v-model:current-page="lookup.currentPage" background
                        v-model:page-size="lookup.pageSize" hide-on-single-page layout="prev, pager, next, jumper "
                        :total="lookup.total" @size-change="handleCurrentChange"
                        @current-change="handleCurrentChange" />
                </el-footer>
            </el-container>
        </el-tab-pane>
        <el-tab-pane label="操作记录">
            <template #label>
                <span class="custom-tabs-label">
                    <el-icon>
                        <Edit />
                    </el-icon>
                    <span>操作记录</span>
                </span>
            </template>
            操作记录
        </el-tab-pane>
    </el-tabs>
    <CatChange :changedits='edit' @updatas='' @deletes='' @cancels="edit = false" :changeForms='form'
        :changeHeads='tableHead' />
</template>

<script>
    import {
        mapState
    } from 'vuex'
    import jsonToExcel from '@/utils/jsonToExcel'
    import * as echarts from "echarts";
    import axios from "@/utils/request";
    import CatSearch from '@/components/CatSearch.vue'
    import CatChange from '@/components/CatChange.vue'
    export default {
        name: 'ResourceMonitor',
        components: {
            CatSearch,
            CatChange
        },
        computed: {
            // Vue计算属性
            ...mapState(['innerHeight', 'screenWidth']),
            tableHeads: function() {
                return this.tableHead.filter((item) => {
                    // 过滤隐藏数据
                    if (item.hide) {
                        return item
                    }
                })
            },
        },
        mounted() {
            this.getdata()
        },
        data() {
            return {
                lookup: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0,
                    export: false,
                    tabname: '资产简报',
                    search: {},
                    csearch: null,
                    data: []
                },
                edit: false,
                fileList: [],
                tableHead: [],
                form: [],
                loading: false
            }
        },
        methods: {
            tabClick(row) {
                console.log(row)
                //筛选数据
                this.edit = false
                this.form = row
            },
            cat_search_get() {
                this.lookup.csearch = null
                this.getdata()
            },
            handleCurrentChange(v) {
                this.lookup.currentPage = v
                this.getdata()
            },
            create_axis(id, option) {
                let re = document.getElementById(id)
                let myChart = echarts.getInstanceByDom(re)
                if (myChart == undefined) {
                    myChart = echarts.init(re);
                }
                myChart.setOption(option)
                window.addEventListener('resize', () => {
                    myChart.resize();
                }, false)
            },
            case_tab(res) {
                switch (this.lookup.tabname) {
                    case '资产简报':
                        this.create_axis('sunburst', {
                            title: {
                                text: '账号状态分析',
                                left: 'center',
                                textStyle: {
                                    color: '#999',
                                    fontWeight: 'normal',
                                    fontSize: 14
                                }
                            },
                            series: [{
                                    type: 'pie',
                                    radius: ['55%', '70%'],
                                    label: {
                                        alignTo: 'edge',
                                        formatter: '{b}{c}个',
                                    },
                                    data: res[0]
                                },
                                {
                                    radius: ['0%', '40%'], // 内圈饼图的半径
                                    center: ['50%', '50%'], // 内圈饼图的位置
                                    label: {
                                        formatter: '{b}{c}个',
                                    },
                                    type: 'pie',
                                    data: res[1]
                                },
                            ]
                        })
                        break;
                    case '资产列表':
                        this.fileList = res.data
                        this.lookup.total = res.total
                        this.tableHead = res.tableHead.tableHead
                    default:
                        break;
                }
            },
            getdata(pane, ev) {
                if (ev) {
                    this.lookup.tabname = pane.props.label
                }
                this.loading = true
                axios({
                    method: "post",
                    url: 'resourcemonitor',
                    data: this.lookup,
                }).then(res => {
                    this.case_tab(res)
                    this.loading = false
                })
            },
            ExporttAPI(e) { //导出表
                this.loading = true
                this.lookup.export = true
                axios({
                    method: "post",
                    url: 'resourcemonitor',
                    data: this.lookup,
                }).then(res => {
                    jsonToExcel(res.data, res.tableHead.tableHead, this.lookup.tabname)
                    this.loading = false
                })
                this.lookup.export = false
            }
        }
    }
</script>

<style scoped>
    .class-tabs>.el-tabs__content {
        padding: 32px;
        color: #6b778c;
        font-size: 32px;
        font-weight: 600;
    }

    .class-tabs .custom-tabs-label .el-icon {
        vertical-align: middle;
    }

    .class-tabs .custom-tabs-label span {
        vertical-align: middle;
        margin-left: 4px;
    }

    .example-showcase .el-dropdown-link {
        cursor: pointer;
        color: var(--el-color-primary);
        display: flex;
        align-items: center;
    }
</style>